const replace = {
  changeSMID: 'einsQ ID ändern',
  reqSMID: 'einsQ ID *',
  SMID: 'einsQ ID',
  SMIDTolltip: 'Die einsQ ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: 'einsQ ID',
  smidLabel: 'einsQ ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die einsQ -ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der einsQ-ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;
