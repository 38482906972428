/* eslint space-before-function-paren: 0 */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { clone, pick } from 'lodash';
// eslint-disable-next-line import/no-named-as-default-member
import CustomSearchField from './CustomSearchField';
// eslint-disable-next-line import/no-named-as-default-member
import ExpanderMultiFilter from './ExpanderMultiFilter';
import { monitoringGetInstallers, monitoringClearFilters, employeeSendRequest } from '../slice';
import { dataListSendRequest } from '../../DataList/slice';
import {
  DATA_LIST_ID,
  GET_INSTALLERS_PARAMS,
  EMPLOYEE_LIST_ID,
  WORK_STATUS_OBJ
} from '../constants';
import i18n from '../../../i18n';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';
import { getPagesSettingsSelector } from '../../../redux-store/selectors/pagesSettings';
import { setPagesSettingQueries, setPagesSetting } from '../../PagesSettings/slice';
import {
  getSignInUserRoleTypeSelector,
  getSignInUser_IdSelector
} from '../../../redux-store/selectors/signIn';
import {
  getMonitoringDataSelector,
  getMonitoringInstallersSelector,
  getMonitoringEmployeesForFilterSelector,
  getMonitoringSelector,
  getMonitoringSelectedFieldsPVInstallerFilterSelector
} from '../../../redux-store/selectors/monitoring';
import { companyUserIdSelector } from '../selectors';
import { Expander, LicenseFilter } from '../../../components';

import '../index.scss';
import {
  ERROR_MONITORING_TICKETS_URL,
  NEW_OEM_URL,
  OEM_CONNECTED_PV_INSTALLERS_URL,
  USERS_EMPLOYEE_URL,
  USERS_PV_INSTALLER_USERS_URL
} from '../../../api/apiUrls';
import { getDataListSelector } from '../../../redux-store/selectors/dataList';
import { APP_ID, EZEE_APP_ID } from '../../../config';

const transformResponse = (data) => {
  const clonedData = clone(data.list);
  const user = {
    first_name: 'Unknown',
    last_name: 'User',
    sm_id: 'Unknown Id',
    id: 'Unknown Id',
    _id: 'Unknown Id'
  };
  const gateway = {
    sm_id: 'Unknown Id',
    _id: 'Unknown Id',
    signal: 'not connected',
    name: 'Unknown Name',
    id: 'Unknown Id',
    lastErrorDate: new Date()
  };

  const company = {
    _id: 'Unknown Id',
    name: 'Unknown Name'
  };

  clonedData.forEach((obj) => {
    if (!obj.user) {
      obj.user = user;
    }

    if (!obj.gateway) {
      obj.gateway = gateway;
    }

    if (!obj.company) {
      obj.company = company;
    }
  });

  return {
    total: data.total,
    data: clonedData
  };
};

export const MainMonitoringFilterV2 = ({ params: defaultParams }) => {
  const dispatch = useDispatch();

  const { [DATA_LIST_ID]: settings } = useSelector(getPagesSettingsSelector);
  const monitoring = useSelector(getMonitoringSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const allInstallers = useSelector(getMonitoringInstallersSelector);
  const userId = useSelector(getSignInUser_IdSelector);
  const employeesForFilter = useSelector(getMonitoringEmployeesForFilterSelector);
  const allData = useSelector(getMonitoringDataSelector);
  const pvInstallerFilter = useSelector(getMonitoringSelectedFieldsPVInstallerFilterSelector);
  const userCompanyId = useSelector(companyUserIdSelector);
  const dataList = useSelector(getDataListSelector);
  const { oem = false } = settings.columns;

  const parseInstallers = () => {
    const allInstallersList = (allInstallers || []).filter((obj) => 'my_company' in obj);
    return {
      options: allInstallersList.map((el) => ({
        name: el.my_company.name,
        id: el.my_company._id,
        admin: el.my_company.admin
      }))
    };
  };

  const parseOems = () => {
    const allOemsList = (dataList?.oems?.data || []).filter((el) => el?.oem?.name)?.sort((a, b) => a.oem?.name.localeCompare(b.oem?.name));

    return {
      options: (allOemsList
        .map((el) => ({
          name: el.oem.name,
          id: el.oem._id
        })))

    };
  };

  const parseEmployees = () => {
    // TODO! need to refactor this old logic
    const assignList = { options: [] };

    if (
      ['root', 'solar_admin', 'oem'].includes(myRoleType)
      && settings.queries.companies
    ) {
      pvInstallerFilter?.value.forEach((pvInstaller) => {
        assignList.options.push({
          name: `Admin ${pvInstaller.name}`,
          id: pvInstaller.admin
        });
      });

      const employeesForFilterEntries = Object.entries(employeesForFilter || {});

      const pvInstallerIds = pvInstallerFilter?.value.map((el) => el.id || el._id) || [];

      employeesForFilterEntries
        .reduce((acc, employeesForFilterEntry) => {
          if (pvInstallerIds.includes(employeesForFilterEntry[0])) {
            return acc.concat(...employeesForFilterEntry[1].data);
          }
          return acc;
        }, [])
        .forEach((el) => {
          assignList.options.push({
            name: `${el.first_name} ${el.last_name}`,
            id: el._id
          });
        });
    }

    if (['pv_installer', ...EMPLOYEE_ROLE_TYPES].includes(myRoleType)) {
      if (Array.isArray(allData) && allData.length) {
        assignList.options.push({
          name: `Admin ${allData[0].company.name} Company`,
          id: allData[0].company.admin?._id || allData[0].company.admin
        });
      }

      if (Object.values(employeesForFilter || {}).length) {
        Object.values(employeesForFilter)[0].data.forEach((el) => {
          assignList.options.push({
            name: `${el.first_name} ${el.last_name}`,
            id: el._id
          });
        });
      }
    }

    return assignList;
  };

  useEffect(() => {
    if (oem && !['oem', 'property_management'].includes(myRoleType)) {
      dispatch(
        dataListSendRequest({
          listID: 'oems',
          dataList: {
            listURL: NEW_OEM_URL,
            transformResponse
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oem]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parsedInstallersList = useMemo(parseInstallers, [allInstallers, settings.queries]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parsedOemsList = useMemo(parseOems, [dataList?.oems?.data, settings.queries]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parsedIAsignedList = useMemo(parseEmployees, [
    myRoleType,
    settings.queries.companies,
    pvInstallerFilter?.value,
    employeesForFilter,
    allData
  ]);
  const isActiveResetBtn = Object.values(
    pick(settings.queries, [
      'done',
      'search',
      'supportContractStatus',
      'isInstallationCompleted',
      'companies',
      'oems',
      'assigned',
      'status',
      'licenseCodes',
      'rentedPVSystemFlag'
    ])
  ).some((value) => Boolean(value));

  const getEmployees = (data) => {
    const listURL = USERS_EMPLOYEE_URL;

    const idOfInstaller = data.companies?.split(',').pop();

    const params = {
      _limit: 50,
      _start: 0,
      _sort: 'first_name:asc',
      company: idOfInstaller
    };

    dispatch(
      employeeSendRequest({
        companyID: idOfInstaller,
        listID: EMPLOYEE_LIST_ID,
        dataList: { listURL, params, transformResponse },
        field: 'employeesForFilter'
      })
    );
  };

  const setFields = (data, isRefetchEmployee) => {
    dispatch(setPagesSettingQueries({ page: DATA_LIST_ID, data }));
    dispatch(
      dataListSendRequest({
        listID: DATA_LIST_ID,
        dataList: {
          listURL: ERROR_MONITORING_TICKETS_URL,
          params: { ...defaultParams, ...settings.queries, ...data },
          transformResponse
        }
      })
    );

    if (isRefetchEmployee && !['property_management'].includes(myRoleType)) {
      getEmployees(data);
    }
  };

  const resetFilters = (data = {}) => {
    setFields({
      done: false,
      search: undefined,
      supportContractStatus: undefined,
      isInstallationCompleted: undefined,
      companies: undefined,
      oems: defaultParams?.oems || undefined,
      assigned: undefined,
      status: undefined,
      _sort: 'lastErrorDate:asc',
      licenseCodes: undefined,
      rentedPVSystemFlag: undefined,
      ...data
    });
    dispatch(monitoringClearFilters());
  };

  const toggleFilter = () => {
    dispatch(
      setPagesSetting({
        page: DATA_LIST_ID,
        data: { isFilteredTabExpanded: !settings.isFilteredTabExpanded }
      })
    );
    if (settings.isFilteredTabExpanded) {
      resetFilters({ search: settings.queries.search });
    }
  };

  useEffect(() => {
    const getInstallersUrl = {
      oem: `${OEM_CONNECTED_PV_INSTALLERS_URL}/${userId}`,
      default: USERS_PV_INSTALLER_USERS_URL
    };

    const GET_INSTALLERS_URL = getInstallersUrl[myRoleType] || getInstallersUrl.default;

    if (['root', 'solar_admin', 'oem', 'oem_employee', 'oem_employee_read_only'].includes(myRoleType)) {
      dispatch(monitoringGetInstallers({ url: GET_INSTALLERS_URL, params: GET_INSTALLERS_PARAMS }));
    } else if (!['property_management'].includes(myRoleType)) {
      getEmployees({ value: [{ id: userCompanyId }] });
    }
  }, [myRoleType]); // eslint-disable-line

  return (
    <div className="main-filter-monitoring-container m--margin-top-20">
      <div className="d-flex px-4 mx-0 align-items-center mb-4">
        <CustomSearchField
          placeholder={i18n.t('searchAllTablesElements')}
          fieldName="CustomSearch"
          listID={DATA_LIST_ID}
          onChangeField={setFields}
          initialValueForCustomSearch={settings.queries.search || ''}
        />
        <button
          type="button"
          onClick={toggleFilter}
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn filter ml-4"
        >
          <i className="fa fal fa-filter mr-2" />
          {i18n.t('filters')}
        </button>
      </div>
      <Expander expanded={settings.isFilteredTabExpanded}>
        <div className="additional-filters__container d-flex flex-wrap px-4">
          {['root', 'solar_admin', 'oem'].includes(myRoleType) && (
            <div className="custom-gap px-0">
              <span>
                {i18n.t('PV Installer')}
                :
              </span>
              <ExpanderMultiFilter
                defaultValue={i18n.t('selectPV')}
                fieldName="PVInstallerFilter"
                id="pvisntaller_filter-reg"
                choosingMultiList={parsedInstallersList}
                queryName="companies"
                onChangeField={setFields}
                listID={DATA_LIST_ID}
                monitoring={monitoring}
              />
            </div>
          )}
          {oem && ['root', 'solar_admin', 'viewer'].includes(myRoleType) && (
            <div className="custom-gap px-0">
              <span>
                {i18n.t('oem')}
                :
              </span>
              <ExpanderMultiFilter
                defaultValue={i18n.t('selectType')}
                fieldName="OemsFilter"
                id="oems_filter-reg"
                choosingMultiList={parsedOemsList}
                queryName="oems"
                onChangeField={setFields}
                listID={DATA_LIST_ID}
                monitoring={monitoring}
              />
            </div>
          )}
          {!['property_management'].includes(myRoleType) && (
            <div className="custom-gap px-0">
              <span>
                {i18n.t('assign')}
                :
              </span>
              <ExpanderMultiFilter
                defaultValue={i18n.t('selectAssign')}
                fieldName="AssignFilter"
                queryName="assigned"
                id="assign_filter-reg"
                choosingMultiList={parsedIAsignedList}
                onChangeField={setFields}
                listID={DATA_LIST_ID}
                monitoring={monitoring}
              />
            </div>
          )}

          <div className="custom-gap px-0">
            <span>
              {i18n.t('workStatusFilterLabel')}
              :
            </span>
            <ExpanderMultiFilter
              defaultValue={i18n.t('workStatusFilterPlaceholder')}
              fieldName="workStatusFilter"
              queryName="status"
              id="status_filter-reg"
              choosingMultiList={WORK_STATUS_OBJ}
              onChangeField={setFields}
              listID={DATA_LIST_ID}
              monitoring={monitoring}
            />
          </div>
          <LicenseFilter
            onChange={setFields}
            role={myRoleType}
            settings={settings}
            classes="custom-gap px-0"
          />
          <div className="switch-container col-xl-auto col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 mr-auto align-self-center px-0">
            <div className="d-flex align-items-center">
              <div className="monitoring-switch d-flex align-items-center">
                <Switch
                  onChange={(value) => setFields({ isInstallationCompleted: value || undefined })}
                  checked={Boolean(settings.queries.isInstallationCompleted)}
                  width={36}
                  height={18}
                  onColor="#1E1E1E"
                  className="react-switch"
                />
              </div>
              <span>{i18n.t('installationStage')}</span>
            </div>
            <div className="d-flex align-items-center">
              <div className="monitoring-switch d-flex align-items-center">
                <Switch
                  onChange={(value) => setFields({ supportContractStatus: value || undefined })}
                  checked={Boolean(settings.queries.supportContractStatus)}
                  width={36}
                  height={18}
                  onColor="#1E1E1E"
                  className="react-switch"
                />
              </div>
              <span>{i18n.t('onlySupportContracts')}</span>
            </div>
            {[EZEE_APP_ID].includes(APP_ID) && (
              <div className="d-flex align-items-center">
                <div className="monitoring-switch d-flex align-items-center">
                  <Switch
                    onChange={(value) => setFields({ rentedPVSystemFlag: value || undefined })}
                    checked={Boolean(settings.queries.rentedPVSystemFlag)}
                    width={36}
                    height={18}
                    onColor="#1E1E1E"
                    className="react-switch"
                  />
                </div>
                <span>{i18n.t('rentedSystem')}</span>
              </div>
            )}
            <div className="d-flex align-items-center">
              <div className="monitoring-switch d-flex align-items-center">
                <Switch
                  onChange={(value) => setFields({ done: value })}
                  checked={settings.queries.done}
                  width={36}
                  height={18}
                  onColor="#1E1E1E"
                  className="react-switch"
                />
              </div>
              <span>{i18n.t('archivedErrors')}</span>
            </div>
          </div>

          <div className="resetBtn col-xl-auto col-lg-4 col-md-4 col-sm-6 col-12 m--margin-bottom-10 align-self-center">
            <button
              className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst exportBtn ${isActiveResetBtn ? '' : 'btn-disabled'}`}
              type="button"
              onClick={() => resetFilters()}
              disabled={!isActiveResetBtn}
            >
              {i18n.t('resetFilters')}
            </button>
          </div>
        </div>
      </Expander>
    </div>
  );
};

MainMonitoringFilterV2.propTypes = {
  params: PropTypes.instanceOf(Object).isRequired
};
