const replace = {
  changeSMID: 'Change einsQ ID',
  reqSMID: 'einsQ ID *',
  SMID: 'einsQ ID',
  SMIDTolltip: 'The einsQ ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: 'einsQ ID',
  smidLabel: 'einsQ ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the einsQ ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the einsQ ID. The gateway is not deleted.'
};

export default replace;
