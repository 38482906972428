import React from 'react';

export const getOrderIcon = (isSorted, direction) => {
  if (!isSorted) {
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
          <path d="M9.37354 11.401V1.97808L7.02051 4.23406C6.90616 4.34208 6.75496 4.40227 6.59766 4.40227C6.44035 4.40227 6.28867 4.34208 6.17432 4.23406C6.11915 4.1819 6.07545 4.11897 6.04541 4.04924C6.01537 3.97952 6 3.90446 6 3.82854C6 3.75262 6.01537 3.67744 6.04541 3.60771C6.07545 3.53799 6.11915 3.47517 6.17432 3.42302L9.55859 0.179003C9.63391 0.107175 9.72665 0.0562226 9.82764 0.0310541C9.93313 -0.00377778 10.0459 -0.00942098 10.1543 0.0146967C10.2627 0.0388143 10.3626 0.0917579 10.4434 0.168017L13.8257 3.41204C13.881 3.46411 13.925 3.52699 13.9551 3.59673C13.9852 3.66646 14.0005 3.74159 14.0005 3.81755C14.0005 3.89351 13.9852 3.96864 13.9551 4.03838C13.925 4.10811 13.881 4.17099 13.8257 4.22307C13.7113 4.33109 13.5596 4.39116 13.4023 4.39116C13.245 4.39116 13.0938 4.33109 12.9795 4.22307L10.5684 1.91008V11.402C10.5684 11.5612 10.5051 11.7138 10.3926 11.8263C10.2801 11.9389 10.1274 12.002 9.96826 12.002C9.80983 12.0007 9.65835 11.9367 9.54688 11.8241C9.4354 11.7115 9.37327 11.5595 9.37354 11.401Z" fill="#040D1780" fillOpacity="0.5" />
          <path d="M3.82707 11.969C3.72598 11.9436 3.63327 11.8922 3.55807 11.82L0.175066 8.57605C0.119793 8.52406 0.0757472 8.46131 0.0456396 8.39166C0.015532 8.322 0 8.24693 0 8.17105C0 8.09517 0.015532 8.02009 0.0456396 7.95044C0.0757472 7.88079 0.119793 7.81803 0.175066 7.76605C0.289418 7.65803 0.440762 7.59785 0.598066 7.59785C0.755369 7.59785 0.906713 7.65803 1.02107 7.76605L3.37307 10.022V0.598047C3.37307 0.519254 3.38859 0.441232 3.41874 0.368437C3.44889 0.295641 3.49309 0.229498 3.5488 0.173783C3.60452 0.118068 3.67066 0.073872 3.74346 0.0437192C3.81625 0.0135663 3.89427 -0.00195312 3.97307 -0.00195312C4.05186 -0.00195313 4.12988 0.0135663 4.20268 0.0437192C4.27547 0.073872 4.34161 0.118068 4.39733 0.173783C4.45304 0.229498 4.49724 0.295641 4.52739 0.368437C4.55755 0.441232 4.57307 0.519254 4.57307 0.598047V10.089L6.98007 7.77705C7.09442 7.66903 7.24576 7.60885 7.40307 7.60885C7.56037 7.60885 7.71171 7.66903 7.82607 7.77705C7.88134 7.82904 7.92538 7.89179 7.95549 7.96144C7.9856 8.03109 8.00113 8.10617 8.00113 8.18205C8.00113 8.25793 7.9856 8.333 7.95549 8.40266C7.92538 8.47231 7.88134 8.53506 7.82607 8.58705L4.44307 11.832C4.32908 11.9406 4.17748 12.0008 4.02007 12C3.9545 11.9999 3.88937 11.9895 3.82707 11.969Z" fill="#040D1780" fillOpacity="0.5" />
        </svg>
      </div>
    );
  }

  if (direction === 'desc') {
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
          <path d="M9.37354 11.401V1.97808L7.02051 4.23406C6.90616 4.34208 6.75496 4.40227 6.59766 4.40227C6.44035 4.40227 6.28867 4.34208 6.17432 4.23406C6.11915 4.1819 6.07545 4.11897 6.04541 4.04924C6.01537 3.97952 6 3.90446 6 3.82854C6 3.75262 6.01537 3.67744 6.04541 3.60771C6.07545 3.53799 6.11915 3.47517 6.17432 3.42302L9.55859 0.179003C9.63391 0.107175 9.72665 0.0562226 9.82764 0.0310541C9.93313 -0.00377778 10.0459 -0.00942098 10.1543 0.0146967C10.2627 0.0388143 10.3626 0.0917579 10.4434 0.168017L13.8257 3.41204C13.881 3.46411 13.925 3.52699 13.9551 3.59673C13.9852 3.66646 14.0005 3.74159 14.0005 3.81755C14.0005 3.89351 13.9852 3.96864 13.9551 4.03838C13.925 4.10811 13.881 4.17099 13.8257 4.22307C13.7113 4.33109 13.5596 4.39116 13.4023 4.39116C13.245 4.39116 13.0938 4.33109 12.9795 4.22307L10.5684 1.91008V11.402C10.5684 11.5612 10.5051 11.7138 10.3926 11.8263C10.2801 11.9389 10.1274 12.002 9.96826 12.002C9.80983 12.0007 9.65835 11.9367 9.54688 11.8241C9.4354 11.7115 9.37327 11.5595 9.37354 11.401Z" fill="var(--primary-color)" fillOpacity="0.5" />
          <path d="M3.82707 11.969C3.72598 11.9436 3.63327 11.8922 3.55807 11.82L0.175066 8.57605C0.119793 8.52406 0.0757472 8.46131 0.0456396 8.39166C0.015532 8.322 0 8.24693 0 8.17105C0 8.09517 0.015532 8.02009 0.0456396 7.95044C0.0757472 7.88079 0.119793 7.81803 0.175066 7.76605C0.289418 7.65803 0.440762 7.59785 0.598066 7.59785C0.755369 7.59785 0.906713 7.65803 1.02107 7.76605L3.37307 10.022V0.598047C3.37307 0.519254 3.38859 0.441232 3.41874 0.368437C3.44889 0.295641 3.49309 0.229498 3.5488 0.173783C3.60452 0.118068 3.67066 0.073872 3.74346 0.0437192C3.81625 0.0135663 3.89427 -0.00195312 3.97307 -0.00195312C4.05186 -0.00195313 4.12988 0.0135663 4.20268 0.0437192C4.27547 0.073872 4.34161 0.118068 4.39733 0.173783C4.45304 0.229498 4.49724 0.295641 4.52739 0.368437C4.55755 0.441232 4.57307 0.519254 4.57307 0.598047V10.089L6.98007 7.77705C7.09442 7.66903 7.24576 7.60885 7.40307 7.60885C7.56037 7.60885 7.71171 7.66903 7.82607 7.77705C7.88134 7.82904 7.92538 7.89179 7.95549 7.96144C7.9856 8.03109 8.00113 8.10617 8.00113 8.18205C8.00113 8.25793 7.9856 8.333 7.95549 8.40266C7.92538 8.47231 7.88134 8.53506 7.82607 8.58705L4.44307 11.832C4.32908 11.9406 4.17748 12.0008 4.02007 12C3.9545 11.9999 3.88937 11.9895 3.82707 11.969Z" fill="#040D1780" fillOpacity="0.5" />
        </svg>
      </div>
    );
  }

  if (direction === 'asc') {
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
          <path d="M9.37354 11.401V1.97808L7.02051 4.23406C6.90616 4.34208 6.75496 4.40227 6.59766 4.40227C6.44035 4.40227 6.28867 4.34208 6.17432 4.23406C6.11915 4.1819 6.07545 4.11897 6.04541 4.04924C6.01537 3.97952 6 3.90446 6 3.82854C6 3.75262 6.01537 3.67744 6.04541 3.60771C6.07545 3.53799 6.11915 3.47517 6.17432 3.42302L9.55859 0.179003C9.63391 0.107175 9.72665 0.0562226 9.82764 0.0310541C9.93313 -0.00377778 10.0459 -0.00942098 10.1543 0.0146967C10.2627 0.0388143 10.3626 0.0917579 10.4434 0.168017L13.8257 3.41204C13.881 3.46411 13.925 3.52699 13.9551 3.59673C13.9852 3.66646 14.0005 3.74159 14.0005 3.81755C14.0005 3.89351 13.9852 3.96864 13.9551 4.03838C13.925 4.10811 13.881 4.17099 13.8257 4.22307C13.7113 4.33109 13.5596 4.39116 13.4023 4.39116C13.245 4.39116 13.0938 4.33109 12.9795 4.22307L10.5684 1.91008V11.402C10.5684 11.5612 10.5051 11.7138 10.3926 11.8263C10.2801 11.9389 10.1274 12.002 9.96826 12.002C9.80983 12.0007 9.65835 11.9367 9.54688 11.8241C9.4354 11.7115 9.37327 11.5595 9.37354 11.401Z" fill="#040D1780" fillOpacity="0.5" />
          <path d="M3.82707 11.969C3.72598 11.9436 3.63327 11.8922 3.55807 11.82L0.175066 8.57605C0.119793 8.52406 0.0757472 8.46131 0.0456396 8.39166C0.015532 8.322 0 8.24693 0 8.17105C0 8.09517 0.015532 8.02009 0.0456396 7.95044C0.0757472 7.88079 0.119793 7.81803 0.175066 7.76605C0.289418 7.65803 0.440762 7.59785 0.598066 7.59785C0.755369 7.59785 0.906713 7.65803 1.02107 7.76605L3.37307 10.022V0.598047C3.37307 0.519254 3.38859 0.441232 3.41874 0.368437C3.44889 0.295641 3.49309 0.229498 3.5488 0.173783C3.60452 0.118068 3.67066 0.073872 3.74346 0.0437192C3.81625 0.0135663 3.89427 -0.00195312 3.97307 -0.00195312C4.05186 -0.00195313 4.12988 0.0135663 4.20268 0.0437192C4.27547 0.073872 4.34161 0.118068 4.39733 0.173783C4.45304 0.229498 4.49724 0.295641 4.52739 0.368437C4.55755 0.441232 4.57307 0.519254 4.57307 0.598047V10.089L6.98007 7.77705C7.09442 7.66903 7.24576 7.60885 7.40307 7.60885C7.56037 7.60885 7.71171 7.66903 7.82607 7.77705C7.88134 7.82904 7.92538 7.89179 7.95549 7.96144C7.9856 8.03109 8.00113 8.10617 8.00113 8.18205C8.00113 8.25793 7.9856 8.333 7.95549 8.40266C7.92538 8.47231 7.88134 8.53506 7.82607 8.58705L4.44307 11.832C4.32908 11.9406 4.17748 12.0008 4.02007 12C3.9545 11.9999 3.88937 11.9895 3.82707 11.969Z" fill="var(--primary-color)" fillOpacity="0.5" />
        </svg>
      </div>
    );
  }

  return null;
};
