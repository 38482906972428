import React, { useState, useRef, useEffect } from 'react';
import { Field, formValueSelector, reduxForm, change, destroy, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { keyBy } from 'lodash';
import { addNotification } from '../../NotificationGenerator/slice';
import { getDataListSelector } from '../../../redux-store/selectors/dataList';
import { PureTextInput, FileSelector, Checkbox } from '../../../components/ReduxFormFields';
import {
  openModalWindow,
  closeModalWindow,
  UPDATE_CONFIRMATION_MODAL_ID,
  CONFIRM_PASS_MODAL_ID
} from '../../ModalWindow/slice';
import { ConfirmPassWindow, UpdateConfirmationWindow } from '../modals';
import { useStartSoftwareUpdateMutation, useGetFirmwareListQuery } from '../services';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getInstalledSMSelectedSelector } from '../../../redux-store/selectors/installedSM';
import i18n from '../../../i18n';
import { installedSMSendUpdate } from '../slice';
import RenderRebootDropdown from '../../../components/ReactTable/renderFunctions/renderRebootDropdown';
import ModalWindow from '../../ModalWindow';
import { releaseGroups } from '../constants';

/**
 * Renders form of updating installed SM
 * @memberof module:InstalledSM
 */
const UpdateSMForm = ({ refetchDataList }) => {
  const dispatch = useDispatch();
  const { installed_sm = {} } = useSelector(getDataListSelector) || {};
  const gateways = useSelector(getInstalledSMSelectedSelector);

  const fileSelectorRef = useRef();
  const [file, setFile] = useState(null);

  const [startSoftwareUpdate, { isSuccess: isSuccessUpdate, error: errorUpdate }] = useStartSoftwareUpdateMutation();

  const { data: firmwareList } = useGetFirmwareListQuery();
  const selectValue = formValueSelector('updateSMForm');
  const version = useSelector((state) => selectValue(state, 'version'));
  const releaseGroupsFormState = useSelector((state) => selectValue(state, 'releaseGroups'));
  const releaseGroupsForFirmwareUpd = useSelector((state) => selectValue(state, 'releaseGroupsForFirmwareUpd'));

  const selectedGroups = Object.entries(releaseGroupsFormState || {}).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(key);
    }
    return acc;
  }, []).join(',');

  const handleOnCloseHandler = () => {
    dispatch(reset('updateSMForm'));

    dispatch(closeModalWindow({ modalID: UPDATE_CONFIRMATION_MODAL_ID }));
  };
  const openUpdConfirmationModal = () => dispatch(openModalWindow({ modalID: UPDATE_CONFIRMATION_MODAL_ID }));
  const onClickUpdateFirmware = () => {
    if (gateways.length > 100) {
      dispatch(openModalWindow({
        modalID: CONFIRM_PASS_MODAL_ID,
        data: {
          label: 'confirmUpdate',
          info: 'confirmUpdatingMoreThen100FirmwareInfo',
          password: 'LargeRollout',
          onSubmit: openUpdConfirmationModal
        }
      }));
      return;
    }
    openUpdConfirmationModal();
  };

  const uploadFirmware = () => {
    dispatch(closeModalWindow({ modalID: 'updateSMForm' }));
    dispatch(change('updateSMForm', 'releaseGroups', {}));

    dispatch(installedSMSendUpdate({ fields: { file, version, releaseGroups: selectedGroups } }));
  };

  const updateGateways = () => {
    startSoftwareUpdate({ data: { gateways, releaseGroup: releaseGroupsForFirmwareUpd, password: 'LargeRollout' } });
    handleOnCloseHandler();
  };

  const handleClose = () => {
    dispatch(closeModalWindow({ modalID: 'updateSMForm' }));
    dispatch(reset('updateSMForm', 'releaseGroups', {}));
  };

  const fileSelectorClickHandler = () => dispatch(openModalWindow({ modalID: 'updateSMForm' }));

  useEffect(() => {
    const groupedInstalledSmData = keyBy(installed_sm?.data || [], '_id');
    const selectedReleaseGroups = [...new Set(gateways.map((gateway) => groupedInstalledSmData[gateway]?.releaseGroup).filter((group) => group))];

    if (selectedReleaseGroups.length === 1) {
      dispatch(change('updateSMForm', 'releaseGroupsForFirmwareUpd', selectedReleaseGroups[0]));
    } else {
      dispatch(change('updateSMForm', 'releaseGroupsForFirmwareUpd', undefined));
    }
  }, [dispatch, gateways, installed_sm?.data]);

  useEffect(() => {
    if (isSuccessUpdate) {
      dispatch(addNotification({ text: i18n.t('success'), type: 'success' }));
      refetchDataList();
    }

    if (errorUpdate) {
      dispatch(addNotification({ text: errorUpdate?.message || i18n.t('error'), type: 'error' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate, errorUpdate, dispatch]);

  useEffect(() => () => dispatch(destroy('updateSMForm')), [dispatch]);

  return (
    <>
      <ConfirmPassWindow modalID={CONFIRM_PASS_MODAL_ID} />
      <form className="px-4">
        <UpdateConfirmationWindow
          modalID={UPDATE_CONFIRMATION_MODAL_ID}
          confirmUpdate={updateGateways}
          handleOnClose={handleOnCloseHandler}
          firmwareList={firmwareList}
        />
        <ModalWindow modalID="updateSMForm">
          <div className="modal-header">
            <h5 className="modal-title">{i18n.t('chooseReleaseGroup')}</h5>
          </div>
          <div className="modal-body mx-4">
            <p className="info">{i18n.t('chooseReleaseGroupText')}</p>
            <div className="support-contracts-container">
              {releaseGroups.map((group) => (
                <div key={group}>
                  <Field
                    name={`releaseGroups.${group}`}
                    type="checkbox"
                    labelClass="support-contracts release-group pl-10"
                    component={Checkbox}
                    label={group}
                  />
                  <hr />
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleClose} />
            <PrimaryButton
              onClickHandler={(e) => fileSelectorRef.current.click(e)}
              i18nLabel="confirmBtn"
              disabled={!selectedGroups}
            />
          </div>
        </ModalWindow>

        <h6 className="set-prof-head">{i18n.t('updFirmware')}</h6>
        <div className="d-flex align-items-center my-3 flex-wrap row-gap-5">
          <div className="d-flex flex-wrap flex-fill firmware-container row-gap-5">
            <Field
              name="version"
              type="text"
              component={PureTextInput}
              placeholder={i18n.t('firmwareVersion')}
              className="update-name-inp"
            />
            <FileSelector
              setFile={setFile}
              className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp btn-inp-file-choose btn-attach"
              buttonLabel={i18n.t('updAttach')}
              handleSubmit={uploadFirmware}
              file={file}
              fileSelectorRef={fileSelectorRef}
              onClick={fileSelectorClickHandler}
            />
          </div>

          <div className="d-flex justify-content-md-end flex-fill align-self-start">
            <div className="reboot-dropdown-wrapper">
              <RenderRebootDropdown />
            </div>
            <div className="install-firmware-btn">
              <PrimaryButton
                onClickHandler={onClickUpdateFirmware}
                i18nLabel="updSubmit"
                isLargeButton
                disabled={!gateways.length}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

UpdateSMForm.propTypes = {
  refetchDataList: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'updateSMForm',
  defaultValues: {
    releaseGroups: {},
    releaseGroupsForFirmwareUpd: undefined
  }
})(UpdateSMForm);

export default form;
