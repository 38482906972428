const replace = {
  changeSMID: "Modifier l'ID du einsQ OEM",
  reqSMID: 'einsQ ID *',
  SMID: 'einsQ ID',
  SMIDTolltip: 'L\'ID einsQ est un identifiant unique de votre einsQ. Vous trouverez cet identifiant sur l\'appareil sous le code QR.',
  enableSmId: 'einsQ ID',
  smidLabel: 'einsQ ID',
  confirmDeletingDesc: 'Confirmez la suppression de toutes les données de cette passerelle en entrant dans le einsQ ID. Après confirmation, ce processus ne peut pas être inversé.',
  confirmDeletingError: 'L\'ID ne correspond pas à l\'ID du einsQ. La passerelle n\'est pas supprimée.'
};

export default replace;
