const replace = {
  changeSMID: 'Ændre einsQ ID',
  reqSMID: 'einsQ ID *',
  SMID: 'einsQ ID',
  SMIDTolltip: 'The einsQ ID er en unik identifikator for din enhed. Du finder dette ID på enheden under QR-koden.',
  enableSmId: 'einsQ ID',
  smidLabel: 'einsQ ID',
  confirmDeletingDesc: 'Bekræft sletningen af ​​alle data fra denne gateway ved at indtaste einsQ ID. Efter bekræftelse kan denne proces ikke vendes.',
  confirmDeletingError: 'ID\'et stemmer ikke overens med einsQ-ID\'et. Gateway\'en er ikke slettet.'
};

export default replace;
